import "./App.css";
import React, { useState } from "react";
import axios from "axios";
import { FadeLoader } from "react-spinners";

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const b64toBlob = (
    b64Data,
    contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    sliceSize = 512
  ) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedFile);

      setLoading(true);
      await axios
        .post(process.env.REACT_APP_API_ENDPOINT_URL, formData)
        .then((res) => {
          const blob = b64toBlob(res.data);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${
            selectedFile.name.split?.(".")?.[0]
          }_transformed.xlsx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 400) {
            alert(error.response.data);
          } else {
            alert("Error");
          }
        });

      setLoading(false);
    } else {
      alert("Select a file");
    }
  };

  return (
    <div className="App">
      <h1>File Upload</h1>
      <form onSubmit={handleSubmit}>
        <label for="images" class="drop-container" id="dropcontainer">
          <span class="drop-title">Drop files here</span>
          or
          <input
            type="file"
            id="images"
            accept=".xlsx"
            required
            onChange={handleFileChange}
            onDrop={handleFileChange}
          />
          {loading && (
            <div className="loadingDiv">
              <FadeLoader color="#1465d0" />
            </div>
          )}
        </label>
        <div>
          <button type="submit">Send</button>
        </div>
      </form>
    </div>
  );
}

export default App;
